import React, { useEffect, useState, useRef } from 'react';
import '../../assets/styles/product/productcard.scss';
import { toast } from 'react-toastify';
import { apiJson } from '../../Api';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

const en_translation = require('../../assets/locales/en/translation.json');
const ar_translation = require('../../assets/locales/ar/translation.json');

export const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phone: Yup.string()
    .required("Phone number is required")
    .test(
      "phone",
      "Phone number is invalid",
      (val) => {
        if (!val) return false;
        try {
          const phoneNumber = parsePhoneNumber('+' + val);

          return phoneNumber.isValid();
        } catch {
          return false;
        }
      }
    )
});


function NotifyUser({setShowNotifyModel, showNotifyModel ,id, language, isSmall = false}) {
  let message = '';
  const contactNumber = localStorage.getItem('contact_number') || '';
  const initialValues = {
    email: localStorage.getItem('uid') || '',
    phone: contactNumber[0] === '+' ? contactNumber.slice(1) : contactNumber,
  };
  const { t } = useTranslation();
  const handleSubmit = async (values) => {
    apiJson.notify
      .notifyMe({
        ...values,
        phone: `+${values.phone}`,
        orderable_id: id,
      })
      .then((response) => {
        if (response.status === 500) {
          message = response.message;
          showError();
        } else {
          message = response.data.message;
          showSuccess();
        }
        setShowNotifyModel(false);
      });
  };

  const showSuccess = () => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showError = () => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Modal
      show={showNotifyModel}
      onHide={() => setShowNotifyModel(false)}
      closeModal={() => setShowNotifyModel(false)}
      size={isSmall ? "sm": "md"}
      aria-labelledby="contained-modal-title-vcenter"
      className={isSmall?"service-modal-mobile":"service-modal"}
      centered
    >
      <Modal.Body>
        <div className="p-4">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="mb-2">
                  {t("item-detail.email") + ":"}
                  <Field type="text" name="email" disabled={initialValues.email} placeholder="Enter email" className="form-control" />
                  <ErrorMessage name="email" component="div" className="text-danger" />
                </div>
                <div className="mb-1">
                  {t("item-detail.phone-number") + ":"}

                  <PhoneInput
                    inputProps={{ name: 'phone', maxLength: 17 }}
                    placeholder={"Enter phone number"}
                    countryCodeEditable={false}
                    country={"ae"}
                    value={values.phone}
                    onChange={(val) => setFieldValue('phone', val)}
                    enableLongNumbers={false}
                    inputStyle={isSmall ? { width: '100%' } : { minWidth: '100%' }}
                    disabled={initialValues.phone}
                  />
                  <ErrorMessage name="phone" component="div" className="text-danger" />
                </div>
                <button type="submit" disabled={isSubmitting} className="continue-btn mt-4" >
                  <span className="add-to-cart-text">
                    {isSubmitting ? (
                      <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <ClipLoader color="#fff" loading size={20} />
                      </div>
                    ) : (
                      language === "en"
                        ? en_translation["item-detail"]["notify-me"]
                        : ar_translation["item-detail"]["notify-me"]
                    )}
                  </span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal> 
  );
}

export default NotifyUser;
