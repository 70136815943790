import React, { Fragment, useRef } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import ProductCard from "./ProductCard";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";

import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../../assets/styles/product/productlist.scss";

import { getDesktopImage } from "../../utils/utils.js";
import CartSidebar from "../customer/cart/CartSidebar";
import { useOutsideAlerter } from "../../utils/CustomHooks";

const screenBreakpoints = {
  md: 768,
  lg: 992,
  xl: 1024,
  xxl: 1200,
  xxxl: 1440,
};
SwiperCore.use([Pagination, Navigation]);

const ProductList = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const sideCartRef = useRef(null);
  const [showCart, setShowCart] = useState(false);
  useOutsideAlerter(sideCartRef, setShowCart);

  const setShowCartSidebar = (val) => {
    setShowCart(val);
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleWindowSizeChange);

  const productCountPerSlideView = () => {
    if (width >= screenBreakpoints.xxxl) {
      return 5;
    } else if (width >= screenBreakpoints.xxl) {
      return 5;
    } else if (width >= screenBreakpoints.xl) {
      return 4;
    } else if (width >= screenBreakpoints.lg) {
      return 3;
    } else if (width >= screenBreakpoints.md) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <Fragment>
      <div className="product-list-desktop">
        <div className="product-list-container mx-auto">
          <Swiper
            spaceBetween={15}
            slidesPerView={productCountPerSlideView()}
            pagination={{
              clickable: true,
            }}
            navigation={true}
          >
            {props.products?.map((product) =>
              product.variants ?
                product.variants.length > 0 ?
                  (
                    <SwiperSlide key={product.id + Math.random() + "prod"}>
                      <ProductCard
                        key={product.id + Math.random() + "prod"}
                        home={props.home}
                        product={{
                          id: product.id,
                          slug: product.slug,
                          line_item_id: product.variants[0].id,
                          img: getDesktopImage(
                            product.variants[0].featured_image_resized,
                            product.variants[0].featured_image
                          ),
                          type: "products",
                          description: product[`name_${props.language}`],
                          storeLogo: product.store_logo,
                          description_en: product[`name_en`],
                          category_en: product.category_title_en,
                          price_after_discount: parseFloat(
                            product.variants[0].discounted_price
                          ),
                          price_before_discount: parseFloat(
                            product.variants[0].price
                          ),
                          rating: parseInt(product.average_rating),
                          count: product.reviews_count,
                          wishlisted: product.variants[0].wishlisted,
                          sku: product.variants[0].sku,
                          coupon: product.coupon,
                          is_inquiry: product.is_inquiry,
                          product_type: product.product_type,
                          start_price: product.variants[0].start_price,
                          end_price: product.variants[0].end_price,
                          quantity: product.variants[0].quantity
                        }}
                        onQuickViewClicked={props.onQuickViewClicked}
                        setShowCartSidebar={setShowCartSidebar}
                      />
                    </SwiperSlide>
                  )
                  :
                  <></>
                : (
                  <SwiperSlide key={product.id + Math.random() + "ser"}>
                    <ProductCard
                      key={product.id + Math.random() + "ser"}
                      isHome={props.home}
                      product={{
                        id: product.id,
                        img: getDesktopImage(
                          product.featured_image_resized,
                          product.featured_image
                        ),
                        type: "services",
                        slug: product.slug,
                        description: product[`name_${props.language}`],
                        description_en: product[`name_en`],
                        storeLogo: product.store_logo,
                        category_en: product.category_title_en,
                        price_after_discount: parseFloat(product.discounted_price),
                        price_before_discount: parseFloat(product.price),
                        rating: parseInt(product.average_rating),
                        count: product.reviews_count,
                        wishlisted: product.wishlisted,
                        coupon: product.coupon,
                        is_inquiry: product.is_inquiry,
                        product_type: product.product_type,
                        start_price: product.start_price,
                        end_price: product.end_price
                      }}
                    />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </div>
        {showCart && <CartSidebar refs={sideCartRef} bool={showCart} />}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ProductList);
