import React, { Fragment, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "../../assets/styles/product/productcategorylist.scss";
import ProductCategory from "./ProductCategory";
import { connect } from "react-redux";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import "../../assets/styles/homepage/mainslider.scss";
import cattest from "../../assets/images/Appliances-IconsV2.png";
SwiperCore.use([Pagination, Navigation]);
const ProductCategoryList = (props) => {
  // const [categories, setCategories] = useState([]);

  useEffect(() => { }, []);

  // useEffect(() => {
  //   var cat = [];
  //   Object.entries(props.categories).map((category) => {
  //     if (category.position == 1) {
  //       cat[1] = category;
  //     }
  //   });
  //   Object.entries(props.categories).map((category) => {
  //     if (category.position == 11) {
  //       cat[0] = category;
  //     }
  //   });
  //   Object.entries(props.categories).map((category) => {
  //     if (category.position >= 12 && category.position <= 14) {
  //       cat = cat.concat(cat);
  //     }
  //   });
  //   setCategories(cat);
  // }, []);
  return (
    <Fragment>

      <div className="product-category-list-container d-xl-flex mx-auto">
        {
          props.categories.length > 0 &&
          (props.categories.length < 9 ? (
            // eslint-disable-next-line
            props.categories.map((category, i) => {
              // alert(JSON.stringify(category));
              // eslint-disable-next-line
              // if (category.position == 1) {
              //   category.position = 12;
              // }
              if (category.position >= 11) {
                return (
                  <ProductCategory
                    key={category.id}
                    id={
                      category.sub_categories?.length > 0
                        ? [
                          category.id,
                          ...category.sub_categories.map(
                            (subCat) => subCat.id
                          ),
                        ]
                        : [category.id]
                    }
                    product={{
                      img: cattest,
                      type: category[`title_${props.language}`],
                    }}
                  />
                );
              }
            })
          ) : (
            <Swiper
              spaceBetween={0}
              slidesPerView={10}
              pagination={{
                clickable: true,
              }}
              navigation={true}
            >
              {/* <SwiperSlide
                onClick={(e) => e.preventDefault()}
                key="0"
              >
                <div className="product-category-container mr-1">
                  <div className="product-category-image-container ">
                    <Link
                      to={{
                        pathname: "/" +
                          props.countryUrl +
                          "-" +
                          props.language +
                          "/offers"
                      }}
                    >
                      <div className="imgofr-desk"><h4><span></span>Offers</h4></div>
                   
                    </Link>
                  </div>
                </div>
              </SwiperSlide> */}
                 {/* <span className="ofrtxt">Offers</span> */}
              {props.categories.map((category, i) => {
                return (
                  <SwiperSlide
                    onClick={(e) => e.preventDefault()}
                    key={category.id}
                  >
                    {
                      category.position >= 11 && (
                        <ProductCategory
                          id={category.id}
                          key={category.id}
                          product={{
                            img: category.image,
                            type: category[`title_${props.language}`],
                          }}
                        />
                      )
                    }
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ))
        }
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countryUrl: state.language.countryUrl,
    categories: state.home.categories,
    language: state.language.language,
  };
};

export default connect(mapStateToProps, null)(ProductCategoryList);
