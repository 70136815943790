import React, { Fragment } from "react";
import { MdPhoneIphone } from "react-icons/md";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
  FaCcDiscover,
  FaCcJcb,
} from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import "../../assets/styles/footer/websitefooterdesktop.scss";
import "../../assets/styles/general.scss";
import Logo from "../../assets/images/logo.png";
import FBIcon from "../../assets/icons/facebook.png";
import InstaIcon from "../../assets/icons/instagram.png";
import YoutubeIcon from "../../assets/icons/linekdin.png";
import twitterIcon from "../../assets/icons/twitter.png";
import visaIcon from "../../assets/icons/visa-card.png";
import masterIcon from "../../assets/icons/master-card.png";
import codIcon from "../../assets/icons/cash1.png";
import mailIcon from "../../assets/icons/mail.png";
import phoneIcon from "../../assets/icons/phone-call.png";
import sogplaystoreicon from "../../assets/images/playstore.svg";
import sogappstoreicon from "../../assets/images/appstore.svg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const WebsiteFooterDesktop = (props) => {
  const { t } = useTranslation();
  const BECOME_SELLER_LINK = process.env.REACT_APP_VENDOR_DOMAIN;

  return (
    <Fragment>
      <div className="website-footer-desktop  mx-auto px-0">
        <div className="coloured-bottom-footer">
          <div className="row follow-us-row all-page-container mx-auto d-flex helpfooter">
            <div className="col-sm-4 copy-right-col">
              <div className="help">Need Help?</div>
            </div>

            <div className="col-sm-8 color-col">

              <div className="channel d-flex justify-content-center align-items-center flex-nowrap helpcolright">

                <div className="">
                  <img
                    src={phoneIcon}
                    className="pointer"
                    style={{ height: "30px", width: "30px" }}
                    alt=""
                  />
                  <span className="helptex">+971 54 306 4845</span>
                </div>
                <div className="">
                  <img
                    src={mailIcon}
                    className="pointer"
                    style={{ height: "30px", width: "30px" }}
                    alt=""
                  />
                  <span className="helptex">sales@alisuq.com</span>
                </div>
                <div className="">
                  <img
                    src={mailIcon}
                    className="pointer"
                    style={{ height: "30px", width: "30px" }}
                    alt=""
                  />
                  <span className="helptex">customerservice@alisuq.com</span>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className="bottom-footer all-page-container mx-auto">
          <div className="row about-and-contact-row mx-auto px-4">
            <div className="col-md-6 col-lg-2 px-md-0 px-lg-0 d-flex justify-content-end footer-menu-col">
              <div className="header-row px-lg-0 d-flex flex-column">
                <div className="header mb-2 text-left">
                  {t("dekstop-footer.top-categories.top-categories")}
                </div>
                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_category%5B%5D=194&filter_by_category%5B%5D=271&filter_by_category%5B%5D=269&filter_by_category%5B%5D=270&filter_by_category%5B%5D=272&filter_by_category%5B%5D=274&filter_by_category%5B%5D=275&filter_by_category%5B%5D=273&tab=p"
                  }}
                >
                  {t("dekstop-footer.top-categories.top-categories1")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_category%5B%5D=184&filter_by_category%5B%5D=217&filter_by_category%5B%5D=215&filter_by_category%5B%5D=216&tab=p"
                  }}
                >
                  {t("dekstop-footer.top-categories.top-categories2")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_category%5B%5D=191&filter_by_category%5B%5D=259&filter_by_category%5B%5D=388&filter_by_category%5B%5D=389&filter_by_category%5B%5D=390&filter_by_category%5B%5D=391&filter_by_category%5B%5D=392&filter_by_category%5B%5D=393&filter_by_category%5B%5D=394&filter_by_category%5B%5D=395&filter_by_category%5B%5D=396&filter_by_category%5B%5D=397&filter_by_category%5B%5D=262&filter_by_category%5B%5D=418&filter_by_category%5B%5D=417&filter_by_category%5B%5D=257&filter_by_category%5B%5D=381&filter_by_category%5B%5D=382&filter_by_category%5B%5D=383&filter_by_category%5B%5D=384&filter_by_category%5B%5D=260&filter_by_category%5B%5D=402&filter_by_category%5B%5D=404&filter_by_category%5B%5D=398&filter_by_category%5B%5D=399&filter_by_category%5B%5D=400&filter_by_category%5B%5D=401&filter_by_category%5B%5D=403&filter_by_category%5B%5D=261&filter_by_category%5B%5D=405&filter_by_category%5B%5D=406&filter_by_category%5B%5D=407&filter_by_category%5B%5D=408&filter_by_category%5B%5D=409&filter_by_category%5B%5D=410&filter_by_category%5B%5D=411&filter_by_category%5B%5D=412&filter_by_category%5B%5D=413&filter_by_category%5B%5D=414&filter_by_category%5B%5D=415&filter_by_category%5B%5D=416&filter_by_category%5B%5D=258&filter_by_category%5B%5D=385&filter_by_category%5B%5D=386&filter_by_category%5B%5D=387&tab=p"
                  }}
                >
                  {t("dekstop-footer.top-categories.top-categories3")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_category%5B%5D=193&filter_by_category%5B%5D=266&filter_by_category%5B%5D=267&filter_by_category%5B%5D=268&tab=p"
                  }}
                >
                  {t("dekstop-footer.top-categories.top-categories4")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_category%5B%5D=183&filter_by_category%5B%5D=205&filter_by_category%5B%5D=206&filter_by_category%5B%5D=207&filter_by_category%5B%5D=210&filter_by_category%5B%5D=211&filter_by_category%5B%5D=212&filter_by_category%5B%5D=214&filter_by_category%5B%5D=208&filter_by_category%5B%5D=209&filter_by_category%5B%5D=213&tab=p"
                  }}
                >
                  {t("dekstop-footer.top-categories.top-categories5")}
                </Link>

              </div>
            </div>

            <div className="col-md-6 col-lg-2 px-md-0 px-lg-0 d-flex justify-content-end footer-menu-col">
              <div className="header-row px-lg-0 d-flex flex-column">
                <div className="header mb-2 text-left">
                  {t("dekstop-footer.top-sellers.top-sellers")}
                </div>
                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_store%5B%5D=1"
                  }}
                >
                  {t("dekstop-footer.top-sellers.top-sellers1")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_store%5B%5D=24"
                  }}
                >
                  {t("dekstop-footer.top-sellers.top-sellers2")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_store%5B%5D=34"
                  }}
                >
                  {t("dekstop-footer.top-sellers.top-sellers3")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_store%5B%5D=23"
                  }}
                >
                  {t("dekstop-footer.top-sellers.top-sellers4")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_store%5B%5D=9"
                  }}
                >
                  {t("dekstop-footer.top-sellers.top-sellers5")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_store%5B%5D=25"
                  }}
                >
                  {t("dekstop-footer.top-sellers.top-sellers7")}
                </Link>


              </div>
            </div>
            <div className="col-md-6 col-lg-2 px-md-0 px-lg-0 d-flex justify-content-end footer-menu-col">
              <div className="header-row px-lg-0 d-flex flex-column">
                <div className="header mb-2 text-left">
                  {t("dekstop-footer.top-brands.top-brands")}
                </div>
                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=42"
                  }}
                >
                  {t("dekstop-footer.top-brands.top-brands1")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=143"
                  }}
                >
                  {t("dekstop-footer.top-brands.top-brands2")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=62"
                  }}
                >
                  {t("dekstop-footer.top-brands.top-brands3")}
                </Link>

                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=163"
                  }}
                >
                  {t("dekstop-footer.top-brands.top-brands4")}
                </Link>
                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=44"
                  }}
                >
                  {t("dekstop-footer.top-brands.top-brands5")}
                </Link>
                <Link
                  to={{
                    pathname: "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/items/products",
                    search: "?filter_by_brand%5B%5D=67"
                  }}
                >
                  {t("dekstop-footer.top-brands.top-brands6")}
                </Link>

              </div>
            </div>
            <div className="col-md-6 col-lg-2 px-md-0 px-lg-0 d-flex justify-content-end footer-menu-col">
              <div className="header-row px-lg-0 d-flex flex-column">
                <div className="header mb-2 text-left">
                  {t("dekstop-footer.who-we-are.who-we-are")}
                </div>
                <div className="font-family-fm">
                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/alisuq-story"
                    }
                  >
                    {t("dekstop-footer.who-we-are.story")}
                  </Link>
                  <a href={`${BECOME_SELLER_LINK}`}>
                    {t("dekstop-footer.customer-service.sell-with-us")}
                  </a>
                  {/* <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                    {t("dekstop-footer.who-we-are.careers")}
                  </a>
                  <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                    {t("dekstop-footer.who-we-are.events")}
                  </a> */}
                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/bulk-order"
                    }
                  >
                    {t("dekstop-footer.quick-links.bulk-enq")}
                  </Link>
                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/brand-favourites"
                    }
                  >
                    {t("dekstop-footer.who-we-are.brandfav")}
                  </Link>

                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/brands-az"
                    }
                  >
                    {t("dekstop-footer.who-we-are.brands")}
                  </Link>
                  {/* <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                    {t("dekstop-footer.who-we-are.refer-a-f")}
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 px-md-0 px-lg-0 d-flex justify-content-end footer-menu-col">
              <div className="header-row px-lg-0 d-flex flex-column">
                <div className="header mb-2 text-left">
                  {t("dekstop-footer.customer-service.customer-service")}
                </div>
                <div className="font-family-fm pointer">
                  <Link to="/login">
                    {t("dekstop-footer.quick-links.sign-in")}
                  </Link>
                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/contact-us"
                    }
                  >
                    {t("dekstop-footer.customer-service.contact-us")}
                  </Link>



                  <Link
                    to={
                      "/" +
                      props.countryUrl +
                      "-" +
                      props.language +
                      "/shipping-policy"
                    }
                  >
                    {t("dekstop-footer.customer-service.ship-deliver")}
                  </Link>



                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 px-md-0 px-lg-0 d-flex justify-content-end footer-menu-col">
              <div className="header-row px-lg-0 d-flex flex-column">
                <div className="header mb-2 text-left">
                  {t("dekstop-footer.legal.legal")}
                </div>

                <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/terms-and-conditions"
                  }
                >
                  {t("dekstop-footer.customer-service.toc")}
                </Link>
                <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/return-refunds"
                  }
                >
                  {t("dekstop-footer.customer-service.ret-ref")}
                </Link>
                <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/privacy-policy"
                  }
                >
                  {t("dekstop-footer.customer-service.privacy-policy")}
                </Link>
                <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/consumer-rights"
                  }
                >
                  {t("dekstop-footer.who-we-are.c-rights")}
                </Link>
                <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/payment-options"
                  }
                >
                  {t("dekstop-footer.customer-service.payments")}
                </Link>




                {/* <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                  {t("dekstop-footer.quick-links.track-your-order")}
                </a> */}
                {/* <Link
                  to={
                    "/" +
                    props.countryUrl +
                    "-" +
                    props.language +
                    "/offers"
                  }>
                  {t("dekstop-footer.quick-links.alisuq-offers")}
                </Link> */}
                {/* <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                  {t("dekstop-footer.quick-links.new-launches")}
                </a> */}

                {/* <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                  {t("dekstop-footer.quick-links.daily-ess")}
                </a> */}
                {/* <a href={"/" + props.countryUrl + "-" + props.language + "/"}>
                  {t("dekstop-footer.quick-links.ind-sol")}
                </a> */}
              </div>
            </div>


          </div>
          <div className="shopongo">
            <div className="shopongocol">

              <div className="sog-heading">{t("homePage.shop-on-the-go")}</div>
              <div className="sog-icon-row">
                <a href="https://play.google.com/store/apps/details?id=com.alisuq">
                  <img
                    src={sogplaystoreicon}
                    className="sog-icon"

                    alt="PLaystore" />
                </a>
                <a href="https://apps.apple.com/gb/app/alisuq/id1608295886">
                  <img
                    src={sogappstoreicon}
                    className="sog-icon"

                    alt="App Store" />
                </a>
              </div>
            </div>
            <div className="shopongocol">

              <div className="channel d-flex justify-content-center align-items-center flex-nowrap">
                <a href="https://www.facebook.com/alisuquae">
                  <img
                    src={FBIcon}
                    className="pointer"
                    style={{ height: "38px", width: "38px" }}
                    alt="" />
                </a>
                <a href="https://www.instagram.com/alisuq.com_/">
                  <img
                    src={InstaIcon}
                    className="pointer"
                    style={{ height: "38px", width: "38px" }}
                    alt=""
                  /></a>
                <a href="https://www.linkedin.com/company/alisuq/people/">
                  <img
                    src={YoutubeIcon}
                    className="pointer"
                    style={{ height: "38px", width: "38px" }}
                    alt=""
                  /></a>

                <a href="https://twitter.com/ALISUQ2">
                  <img
                    src={twitterIcon}
                    className="pointer"
                    style={{ height: "38px", width: "38px" }}
                    alt=""
                  /></a>
                {/* <FaFacebook className="pointer" color="#CF9435" />
                <FaInstagram className="pointer" color="#CF9435" />
                <FaYoutube className="pointer" color="#CF9435" /> */}
              </div>

            </div></div>
        </div>
        <div className="coloured-bottom-footer">
          <div className="row follow-us-row all-page-container mx-auto d-flex">
            <div className="col-sm-4 copy-right-col">
              <div className="header">© 2024 ALISUQ.COM</div>
            </div>
            <div className="col-sm-4 social-link-col">

            </div>
            <div className="col-sm-4 color-col">
              <div className="header">{t("dekstop-footer.we-accept")}</div>
              <div className="channel d-flex justify-content-center align-items-center flex-nowrap">
                <span className="secpay">{t("dekstop-footer.secure-payment")}</span>
                <div className="">
                  <img
                    src={codIcon}
                    className="pointer"
                    style={{ height: "30px", width: "30px" }}
                    alt=""
                  />
                  <img
                    src={visaIcon}
                    className="pointer"
                    style={{ height: "25px", width: "25px" }}
                    alt=""
                  />
                </div>
                <div className="">
                  <img
                    src={masterIcon}
                    className="pointer"
                    style={{ height: "25px", width: "25px" }}
                    alt=""
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
    countryUrl: state.language.countryUrl,
  };
};
export default connect(mapStateToProps, null)(WebsiteFooterDesktop);